import {http} from '@/http'

export default {
  list(params) {
    return http.get('/zonal', {params})
  },
  listActive() {
    return http.get('/zonal/active')
  },
  changeStatus(id, status) {
    return http.put(`/zonal/${id}/status`, null, {params: {status}})
  },
  save(data) {
    return http.post('/zonal', data)
  },
  update(id, data) {
    return http.put(`/zonal/${id}`, data)
  },
  get(id) {
    return http.get(`/zonal/${id}`)
  },
  listAll() {
    return http.get('/zonal/all')
  },
}
