import {http} from '@/http'

export default {
  list(params) {
    return http.get('/reject-reason', {params})
  },
  listActive() {
    return http.get('/reject-reason/active')
  },
  changeStatus(id, status) {
    return http.put(`/reject-reason/${id}/status`, null, {params: {status}})
  },
  save(data) {
    return http.post('/reject-reason', data)
  },
  update(id, data) {
    return http.put(`/reject-reason/${id}`, data)
  },
  get(id) {
    return http.get(`/reject-reason/${id}`)
  },
  deleteReason(id) {
    return http.delete(`/reject-reason/${id}`)
  }
}
