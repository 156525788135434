<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    size="lg"
    id="payed-modal"
  >
    <div class="modal-header d-flex justify-content-between align-items-center">
      <span class="modal-title">Marcar como pagado </span>
      <b-button class="modal-close-icon" @click="hideModal" aria-label="Close">
        <i class="ri-close-large-line"></i>
      </b-button>
    </div>

    <section class="modal-body-container">
      <simple-alert
        text="Para actualizar el pago, por favor ingrese la siguiente información:"
        type="light-gray"
        class="mt-2"
      >
        <template slot="icon">
          <span class="modal-icon mr-2">
            <i class="ri-error-warning-line"></i>
          </span>
        </template>
      </simple-alert>

      <form
        action=""
        @submit.prevent="confirm"
        class="custom-form mt-4"
        novalidate
      >
        <div class="form-group d-flex flex-column flex-md-row">
          <label for="payedAmount" class="label">Monto del abono *</label>

          <div class="flex-1">
            <input
              id="payedAmount"
              type="text"
              v-model.trim="payedAmount"
              autocomplete="off"
              maxlength="11"
              class="custom-form-control"
              :class="{
                'custom-form-control-error': $v.payedAmount.$error,
              }"
            />
            <FormError
              v-if="$v.payedAmount.$error && !$v.payedAmount.required"
              message="Monto del abono es requerido"
            />
            <FormError
              v-if="$v.payedAmount.$error && !$v.payedAmount.numeric"
              message="Debe ser un valor numérico, no debe incluir comas"
            />
          </div>
        </div>

        <div class="form-group d-flex flex-column flex-md-row mb-0">
          <label for="paymentDate" class="label">Fecha de abono *</label>

          <div class="flex-1">
            <FormDatepicker
              label-id="paymentDate"
              :show-calendar="showPaymentDate"
              style="border: 0"
              class="custom-form-control p-0"
              :max="new Date()"
              hide-header
              @show="showPaymentDate = true"
              @hidden="showPaymentDate = false"
              v-model="paymentDate"
            />

            <FormError
              v-if="$v.paymentDate.$error && !$v.paymentDate.required"
              message="Fecha de abono es requerida"
            />
          </div>
        </div>
        <div class="form-group-buttons text-right">
          <button
            class="button button-light"
            type="button"
            @click.prevent="hideModal()"
          >
            Cancelar
          </button>
          <button class="button button-primary" type="submit">Confirmar</button>
        </div>
      </form>
    </section>
  </b-modal>
</template>
<script>
import {
  FormDatepicker,
  SimpleAlert,
  ModalMixin,
  FormError,
} from "wize-admin";

import * as moment from 'moment'

import { required, decimal } from "vuelidate/lib/validators";
import { Constants as LocalConstants } from "@/core/utils";


export default {
  name: "PayedModal",
  props: {
    paymentId: Number,
  },
  data() {
    return {
      showPaymentDate: false,
      paymentDate: new Date(),
      payedAmount: null,
    };
  },
  mixins: [ModalMixin],
  validations: {
    payedAmount: { required, decimal },
    paymentDate: { required },
  },
  components: {
    FormDatepicker,
    SimpleAlert,
    FormError,
  },
  methods: {
    confirm() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.$emit(
        "confirm",
        this.paymentId,
        "¿Desea marcar el pago como pagado?",
        {
          status: LocalConstants.PaymentStatus.PAID.key,
          paymentDate: moment(this.paymentDate).format('YYYY-MM-DD'),
          payedAmount: this.payedAmount,
        }
      );
      this.hideModal();
    },
    hideModal() {
      this.paymentDate = new Date();
      this.payedAmount = null;
      this.$emit("hide");
    },
  },
};
</script>

<style lang="stylus">
@import "~@/core/styles/components/payed-modal.styl";
</style>
