import PaymentsView from "../PaymentsView.vue";
import PaymentList from "../Pages/PaymentList.vue";
import PaymentBatchList from "../Pages/PaymentBatchList.vue";
import PaymentBatchUpload from "../Pages/PaymentBatchUpload.vue";
import PaymentMadeList from "../Pages/PaymentMadeList.vue";
import PaymentMadeUpload from "../Pages/PaymentMadeUpload.vue";
import UploadVoucherPage from "../Pages/UploadVoucherPage.vue";

const routes = [
  {
    path: '/pagos',
    component: PaymentsView,
    roles: ['administrator'],
    name: 'payments',
    children: [
      {
        path: 'lista',
        name: 'payments-list',
        component: PaymentList
      },
      {
        path: 'cargas',
        name: 'payments-batch-list',
        component: PaymentBatchList
      },
      {
        path: 'cargas/carga',
        name: 'payments-batch-upload',
        component: PaymentBatchUpload
      },
      {
        path: 'abonos',
        name: 'payments-made-batch-list',
        component: PaymentMadeList
      },
      {
        path: 'abonos/carga',
        name: 'payments-made-upload',
        component: PaymentMadeUpload
      },
      {
        path: ':id/carga',
        name: 'payments-upload-voucher',
        component: UploadVoucherPage,
        props: (route) => {
          const id = route.params.id;
          return {id}
        },
      },
    ]
  },
]

export default routes
