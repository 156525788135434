<template>
  <div class="page-container reject-reason-list">
    <div class="page-title-container">
      <h1 class="page-title">Comprobantes</h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <button
                type="button"
                class="filter d-flex align-items-center justify-content-between border-0"
                @click="showFilters = !showFilters"
              >
                <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                <div>Filtrar comprobantes</div>
              </button>

              <div class="d-flex d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold m-0"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>

                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold"
                  v-b-tooltip.hover
                  title="Descargar Excel"
                  @click="downloadReport()"
                >
                  <i class="ri-download-2-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold m-0"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>

              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Descargar Excel"
                @click="downloadReport()"
              >
                <i class="ri-download-2-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por RUC, razón social o número de factura"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />
            </div>
          </section>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filterList"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="text-white p-0 bg-transparent border-0 ml-2"
              @click="deleteFilter(name)"
            >
              <i class="ri-close-circle-line"></i>
            </button>
          </span>
        </div>
      </div>

      <div class="mb-0">
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Fecha</span>
            </div>
            <div>
              <span>Número de comprobante</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span v-local-date="o.issueDate"></span>
                </div>

                <div>
                  <span>{{ o.voucherCode }}</span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="custom-table-mobile-options mt-3">
                <a href="" @click="download(o.asset)" v-if="o.asset"
                >Descargar comprobante</a
                >

                <a
                  href=""
                  @click="openApprovalModal(o.paymentId)"
                  v-if="o.status === PaymentStatus.AUTHORIZED.key"
                >Aceptar comprobante</a
                >

                <a
                  href=""
                  @click="openReasonModal(o.paymentId)"
                  v-if="o.status === PaymentStatus.AUTHORIZED.key"
                >Rechazar comprobante</a
                >

                <a
                  href=""
                  @click="seeRejectReason(o.rejectReason)"
                  v-if="o.status === PaymentStatus.REJECTED.key"
                >Ver motivo de rechazo</a
                >
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Empresa:</span
                    >
                    <span>{{ o.companyName }}</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Razón social:</span
                    >
                    <span>{{ o.supplierName }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >ID de pago:</span
                    >
                    <span>{{ o.paymentCode }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Forma de pago:</span
                    >
                    <span>{{ o.paymentTerm }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Monto de ID de pago:</span
                    >
                    <span v-decimal:pen="o.paymentAmount"></span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Monto de comprobante:</span
                    >
                    <span v-decimal:pen="o.total"></span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Estado:</span
                    >
                    <span :class="`badge ${getStatus(o.status).color}`">{{
                        getStatus(o.status).label
                      }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
          <tr>
            <th>Fecha</th>
            <th>Empresa</th>
            <th>RUC</th>
            <th>Razón social</th>
            <th>Número <br> de comprobante</th>
            <th>ID <br> de pago</th>
            <th>Forma <br> de pago</th>
            <th>Monto de<br> ID de pago</th>
            <th>Monto <br> de comprobante</th>
            <th>Estado</th>
            <th class="pr-md-3">Opciones</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="o in list" :key="o.id">
            <td class="cell-center" v-local-date="o.issueDate"></td>
            <td class="cell-center">{{ o.companyName }}</td>
            <td class="cell-center">{{ o.supplierId }}</td>
            <td class="cell-center">{{ o.supplierName }}</td>
            <td class="cell-center">{{ o.voucherCode }}</td>
            <td class="cell-center">{{ o.paymentCode }}</td>
            <td class="cell-center">{{ o.paymentTerm }}</td>
            <td class="cell-center" v-decimal:pen="o.paymentAmount"></td>
            <td class="cell-center" v-decimal:pen="o.total"></td>
            <td class="cell-center">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                    getStatus(o.status).label
                  }}</span>
            </td>

            <td class="cell-center d_option pr-md-2 position-relative">
              <DropdownTable v-if="o.status !== PaymentStatus.PENDING.key">
                <template slot="dropdown-content">
                  <DropdownItem
                    text="Descargar comprobante"
                    @click="download(o.asset)"
                    v-if="o"
                  />
                  <DropdownItem
                    text="Aceptar comprobante"
                    @click="openApprovalModal(o.paymentId)"
                    v-if="o.status === PaymentStatus.AUTHORIZED.key"
                  />
                  <DropdownItem
                    text="Rechazar comprobante"
                    @click="openReasonModal(o.paymentId)"
                    v-if="o.status === PaymentStatus.AUTHORIZED.key"
                  />
                  <DropdownItem
                    text="Ver motivo de rechazo"
                    @click="seeRejectReason(o.rejectReason)"
                    v-if="o.status === PaymentStatus.REJECTED.key"
                  />
                </template>
              </DropdownTable>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
          v-if="list.length"
        />

        <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>

    <ReasonForRejectionModal
      :payment-id="selectedPaymentId"
      :reject-reasons="rejectReasons"
      :show="showReasonsModal"
      @hide="showReasonsModal = false"
      @confirm="changeStatus"
    />
    <RejectReasonModal
      :show="showRejectReasonModal"
      @hide="showRejectReasonModal = false"
      :reject-reason="selectedRejectReason"
    />
    <ApprovalModal
      :payment-id="selectedPaymentId"
      :show="showApprovalModal"
      @hide="showApprovalModal = false"
      @confirm="changeStatus"
    />
  </div>
</template>

<script>
import { Alert, Constants, DropdownItem, DropdownTable, FilterMenu, FilterMixin, PaginationMixin, SearchBar, Util, } from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";
import { BusinessService, PaymentService, RejectReasonService, VoucherService, } from "@/core/services";
import { ApprovalModal, ReasonForRejectionModal, RejectReasonModal, } from "@/core/components";
import fileDownload from "js-file-download";

export default {
  components: {
    SearchBar,
    ReasonForRejectionModal,
    DropdownTable,
    DropdownItem,
    FilterMenu,
    RejectReasonModal,
    ApprovalModal,
  },
  data() {
    return {
      list: [],
      PaymentStatus: LocalConstants.PaymentStatus,
      showRejectReasonModal: false,
      selectedRejectReason: null,
      tooltipType: null,
      showReasonsModal: false,
      rejectReasons: [],
      selectedPaymentId: null,
      showApprovalModal: false,
      filterOptions: [
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.MULTIPLE,
          options: [
            LocalConstants.PaymentStatus.PENDING,
            LocalConstants.PaymentStatus.REVIEW,
            LocalConstants.PaymentStatus.AUTHORIZED,
            LocalConstants.PaymentStatus.APPROVED,
            LocalConstants.PaymentStatus.REJECTED,
            LocalConstants.PaymentStatus.PAID,
            LocalConstants.PaymentStatus.INCORRECT,
          ],
        },
        {
          label: "Fecha de comprobante",
          key: "date",
          type: Constants.TypeFilters.DATE,
          isRange: true,
        },
        {
          label: "Empresa",
          key: "businessIds",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
      ],
    };
  },
  mixins: [FilterMixin, PaginationMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "issueDate,desc",
        };
        const data = { search: this.search, ...this.filters };
        const response = await VoucherService.list(data, params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.PaymentStatus);
    },
    async download(asset) {
      try {
        if (asset) {
          this.$store.dispatch("app/loading", true);

          await Util.downloadFile(asset.url, asset.name);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async downloadReport() {
      try {
        await this.$store.dispatch("app/loading", true);

        const data = {search: this.search, ...this.filters};
        const response = await VoucherService.download(data);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    openReasonModal(paymentId) {
      this.selectedPaymentId = paymentId;
      this.showReasonsModal = true;
    },
    openApprovalModal(paymentId) {
      this.selectedPaymentId = paymentId;
      this.showApprovalModal = true;
    },
    changeStatus(paymentId, message, data) {
      const self = this;
      Alert.dialog(message, null, async function () {
        try {
          await self.$store.dispatch("app/loading", true);
          await PaymentService.changeStatus(paymentId, data);
          await self.loadData();
          Alert.success("Estado actualizado correctamente");
        } catch (e) {
          console.error(e);
          Alert.error(e.errors.message);
        } finally {
          await self.$store.dispatch("app/loading", false);
        }
      });
    },
    seeRejectReason(rejectReason) {
      this.selectedRejectReason = rejectReason;
      this.showRejectReasonModal = true;
    },
  },
  async mounted() {
    await this.loadData(this.page, this.pageSize);
    try {
      const reasons = (await RejectReasonService.listActive()).payload;
      reasons.forEach((b) =>
        this.rejectReasons.push({key: b.id, label: b.description})
      );

      const businessList = (await BusinessService.list()).payload;
      const businessOption = this.filterOptions.find(
        (o) => o.key === "businessIds"
      );
      businessList.forEach((b) =>
        businessOption.options.push({key: b.id, label: b.name})
      );
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style lang="stylus" scoped></style>
