import Vue from 'vue'
import '@/core/plugins/vuelidate'
import '@/core/plugins/directives'
import '@/core/plugins/bootstrap'
import '@/core/plugins/abilities'
import '@/core/plugins/smooth-scroll'

import '@/core/styles/app.styl'

// Hamburger Css https://jonsuh.com/hamburgers/
import 'hamburgers/dist/hamburgers.min.css'

// https://remixicon.com/
import 'remixicon/fonts/remixicon.css'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
