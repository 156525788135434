import AuditLogView from "../AuditLogView.vue";
import AuditLog from "../Pages/AuditLog.vue";

const routes = [
  {
    path: '/auditoria',
    component: AuditLogView,
    children: [
      {
        path: '',
        name: 'audit-log',
        roles: ['administrator'],
        component: AuditLog
      },
    ]
  },
]

export default routes
