<template>
  <div class="dashboard-page">
    <section class="dashboard-banner">
      <h1 class="dashboard-title">Control de pagos</h1>

      <section class="dashboard-container">
        <div class="dashboard-card"></div>
      </section>
    </section>
  </div>
</template>

<script>
export default {
  name: "DashboardPage",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="stylus" scoped>
@import "../Styles/Dashboard.styl"
</style>
