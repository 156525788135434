import {http} from '@/http'

export default {
  list(data, params) {
    return http.post('/vouchers/list', data, {params})
  },
  download(data) {
    return http.post('/vouchers/xlsx', data, {responseType: 'blob'})
  },
}
