
const state = {
  menuOpen: true,
  currentSubmenu: localStorage.getItem('currentSubmenu') === null ? [] : localStorage.getItem('currentSubmenu').split(','),
  loading: false,
  currentDevice: null
};

const getters = {
  getCurrentSubmenu: state => state.currentSubmenu,
  isMenuOpen : state=> state.menuOpen,
  isLoading: state => state.loading,
  getCurrentDevice: (state) => state.currentDevice
};

const actions = {
  loading: (store, val) => {
    store.commit('setLoading', val)
  },
};

const mutations = {
  setCurrentSubmenu(state, value) {
      const values = state.currentSubmenu
      state.currentSubmenu = values.includes(value)?  values.filter(e => e !== value) : [...values, value]
      localStorage.setItem('currentSubmenu', state.currentSubmenu)
  },
  toggleMenu(state, value) {
    state.menuOpen = value
  },
  setMenu(state, value) {
    state.menuOpen = value
  },
  setLoading(state, value) {
    state.loading = value
  },
  setCurrentDevice(state, value) {
    state.currentDevice = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
