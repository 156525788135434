import { http } from '@/http'

export default {
  list(data, params) {
    return http.post('/audit-logs/list', data, {params})
  },
  download(data) {
    return http.post('/audit-logs/xlsx', data, {responseType: 'blob'})
  }
}
