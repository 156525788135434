<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    size="lg"
    id="approval-modal"
  >
    <div class="modal-header d-flex justify-content-between align-items-center">
      <span class="modal-title">Aceptar comprobante</span>
      <b-button class="modal-close-icon" @click="hideModal" aria-label="Close">
        <i class="ri-close-large-line"></i>
      </b-button>
    </div>

    <section class="modal-body-container">
      <simple-alert
        text="Por favor indique si el cliente tiene una retención:"
        type="light-gray"
        class="mt-2"
      >
        <template slot="icon">
          <span class="modal-icon mr-2">
            <i class="ri-error-warning-line"></i>
          </span>
        </template>
      </simple-alert>

      <form
        action=""
        @submit.prevent="confirm"
        class="custom-form mt-4"
        novalidate
      >
        <div class="form-group d-flex flex-column flex-md-row">
          <label for="payedAmount" class="label"
            >El proveedor tiene retención</label
          >

          <div class="flex-1">
            <b-form-checkbox
              id="hasRetention"
              v-model="hasRetention"
              name="check-button"
              switch
              size="lg"
            >
            </b-form-checkbox>
          </div>
        </div>

        <div
          class="form-group d-flex flex-column flex-md-row mb-0"
          v-if="hasRetention"
        >
          <label for="paymentDate" class="label">Monto de retención *</label>

          <div class="flex-1">
            <input
              id="prefix"
              type="text"
              v-model.trim="retentionAmount"
              autocomplete="off"
              maxlength="11"
              class="custom-form-control"
              :class="{
                'custom-form-control-error': $v.retentionAmount.$error,
              }"
            />

            <FormError
              v-if="$v.retentionAmount.$error && !$v.retentionAmount.required"
              message="Monto de retención es requerido"
            />
            <FormError
              v-if="$v.retentionAmount.$error && !$v.retentionAmount.decimal"
              message="El monto de retención debe ser un valor numérico"
            />
          </div>
        </div>
        <div class="form-group-buttons text-right">
          <button
            class="button button-light"
            type="button"
            @click.prevent="hideModal()"
          >
            Cancelar
          </button>
          <button class="button button-primary" type="submit">Confirmar</button>
        </div>
      </form>
    </section>
  </b-modal>
</template>
<script>
import { FormError, SimpleAlert, ModalMixin } from "wize-admin";
import { decimal, requiredIf } from "vuelidate/lib/validators";
import { Constants as LocalConstants } from "@/core/utils";

export default {
  name: "ApprovalModal",
  props: {
    paymentId: Number,
  },
  data() {
    return {
      hasRetention: false,
      retentionAmount: null,
    };
  },
  mixins: [ModalMixin],
  validations: {
    retentionAmount: {
      decimal,
      required: requiredIf(function () {
        return this.hasRetention;
      }),
    },
  },
  components: {
    SimpleAlert,
    FormError,
  },
  methods: {
    confirm() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.$emit("confirm", this.paymentId, "¿Desea aceptar el documento?", {
        status: LocalConstants.PaymentStatus.APPROVED.key,
        hasRetention: this.hasRetention,
        retentionAmount: this.retentionAmount,
      });
      this.hideModal();
    },
    hideModal() {
      this.$emit("hide");
    },
  },
};
</script>

<style lang="stylus">
@import "~@/core/styles/components/approval-modal.styl";
</style>
