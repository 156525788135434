import SuppliersView from "../SuppliersView.vue";
import SupplierList from "../Pages/SupplierList.vue";
import SupplierDetails from "../Pages/SupplierDetails.vue";
import SupplierEditor from "../Pages/SupplierEditor.vue";

const routes = [
  {
    path: '/proveedor',
    component: SuppliersView,
    children: [
      {
        path: '',
        roles: ['administrator'],
        name: 'supplier-list',
        component: SupplierList,
      },
      {
        path: ':supplierId/detalle',
        roles: ['administrator'],
        name: 'supplier-details',
        component: SupplierDetails,
      },
      {
        path: 'crear',
        roles: ['administrator'],
        name: 'supplier-create',
        component: SupplierEditor,
      },
      {
        path: ':supplierId',
        roles: ['administrator'],
        name: 'supplier-edit',
        component: SupplierEditor,
      },
    ]
  }
]

export default routes
