<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    size="lg"
    id="authorize-modal"
  >
    <div class="modal-header d-flex justify-content-between align-items-center">
      <span class="modal-title">Autorizar</span>
      <b-button class="modal-close-icon" @click="hideModal" aria-label="Close">
        <i class="ri-close-large-line"></i>
      </b-button>
    </div>

    <section class="modal-body-container">
      <simple-alert
        text="Para autorizar el pago, por favor ingrese la siguiente información:"
        type="light-gray"
        class="mt-2"
      >
        <template slot="icon">
          <span class="modal-icon mr-2">
            <i class="ri-error-warning-line"></i>
          </span>
        </template>
      </simple-alert>

      <form
        action=""
        @submit.prevent="confirm"
        class="custom-form mt-4"
        novalidate
      >
        <div class="form-group d-flex flex-column flex-md-row">
          <label for="payedAmount" class="label">Proyecto *</label>

          <div class="flex-1">
            <FormSelect
              id="projectType"
              v-model="projectType"
              :items="projectTypes"
              defaultOption="Elegir proyecto"
              :showError="$v.projectType.$error && !$v.projectType.required"
            />
          </div>
        </div>

        <div class="form-group d-flex flex-column flex-md-row mb-0">
          <label for="paymentDate" class="label">Periodo *</label>

          <div class="flex-1">
            <input
              id="prefix"
              type="text"
              v-model.trim="period"
              autocomplete="off"
              maxlength="11"
              placeholder="202409-001"
              class="custom-form-control"
              :class="{
                'custom-form-control-error': $v.period.$error,
              }"
            />
          </div>
        </div>
        <div class="form-group-buttons text-right">
          <button
            class="button button-light"
            type="button"
            @click.prevent="hideModal()"
          >
            Cancelar
          </button>
          <button class="button button-primary" type="submit">Confirmar</button>
        </div>
      </form>
    </section>
  </b-modal>
</template>
<script>
import { SimpleAlert, ModalMixin } from "wize-admin";
import { required } from "vuelidate/lib/validators";
import { Constants as LocalConstants } from "@/core/utils";

export default {
  name: "AuthorizeModal",
  props: {
    projectTypes: Array,
    paymentId: Number,
  },
  data() {
    return {
      projectType: null,
      period: null,
    };
  },
  mixins: [ModalMixin],
  validations: {
    projectType: { required },
    period: { required },
  },
  components: {
    SimpleAlert,
  },
  methods: {
    confirm() {
      this.$v.$touch();
      if (this.$v.$error) return;
      const projectType = this.projectTypes.find(
        (o) => o.key === this.projectType
      );
      this.$emit(
        "confirm",
        this.paymentId,
        "¿Desea autorizar el estado del pago?",
        {
          status: LocalConstants.PaymentStatus.AUTHORIZED.key,
          projectTypeId: projectType.key,
          period: this.period,
        }
      );
      this.hideModal();
    },
    hideModal() {
      this.projectType = null;
      this.period = null;
      this.$emit("hide");
    },
  },
};
</script>

<style lang="stylus">
@import "~@/core/styles/components/authorize-modal.styl";
</style>
