<template>
  <header class="header bg-white">
    <div class="container d-md-flex">
      <section class="d-flex justify-content-between align-items-center">
        <!-- Logo -->
        <figure class="logo-container">
          <img src="@/core/assets/images/login/logo.svg" alt="Logo Wize" class="logo" />
        </figure>

        <!-- Hamburger -->
        <button class="hamburger hamburger--collapse d-md-none" :class="{'is-active': showNav}" type="button" @click="showNav = !showNav">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </section>

      <!-- Nav -->
      <nav
        class="nav bg-white w-100 d-md-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center"
        :class="{'nav--active': showNav}"
      >
        <section class="d-flex flex-column flex-md-row align-items-center pl-md-4">
          <router-link
            class="nav-link mb-3 mb-md-0"
            v-for="(item, index) in links"
            :key="index" :to="{path: item.route}"
            @click.native="showNav = false"
          >
            {{ item.text }}
          </router-link>
        </section>

        <div class="d-flex mt-3 mt-md-0 pb-md-2">
          <AccessButton @success="showNav = false" />

          <router-link to="/" class="button-blue">Ir a documentación</router-link>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import AccessButton from './AccessButton'

export default {
  name: "LoginHeader",

  data() {
    return {
      showNav: false,
      links: []
    }
  },

  components: {
    AccessButton
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/header"
</style>
