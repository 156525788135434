import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginRoutes from '@/modules/Login/Routes'
import DashboardRoutes from '@/modules/Dashboard/Routes'
import SuppliersRoutes from '@/modules/Suppliers/Routes'
import ConfigRoutes from '@/modules/Config/Routes'
import PaymentsRoutes from '@/modules/Payments/Routes'
import VouchersRoutes from '@/modules/Vouchers/Routes'
import AuditLogRoutes from '@/modules/AuditLog/Routes'
import store from '../store'
import {defineAbilitiesFor} from '@/core/plugins/abilities'

Vue.use(VueRouter);

let routes = [];

routes = routes.concat(LoginRoutes, DashboardRoutes, SuppliersRoutes, ConfigRoutes, PaymentsRoutes, VouchersRoutes,
  AuditLogRoutes);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.excludeAuth) {
    if (!store.getters['auth/isAuthenticated']) {
      next();
    } else {
      next({name: 'dashboard'})
    }

  } else {
    if (store.getters['auth/isAuthenticated']) {
      if (!store.getters['auth/isTokenValid']) {
        try {
          await store.dispatch('auth/checkValidToken')
          const user = store.getters['auth/getUser']
          defineAbilitiesFor(user.roles)
          next();
        } catch (e) {
          console.error(e)
          next({name: 'login'});
        }
      } else {
        next();
      }
    } else {
      next({name: 'login'});
    }
  }
});

export default router
