import LoginView from "../LoginView.vue";
import LoginPage from "../Pages/LoginPage.vue";

const routes = [
  {
    path: '/login',
    component: LoginView,
    children: [
      {
        path: '',
        name: 'login',
        component: LoginPage,
        meta: {excludeAuth: true}
      },
      {
        path: 'callback',
        name: 'callback',
        component: LoginPage,
        meta: {excludeAuth: true}
      },
    ]
  },
]

export default routes
