<template>
  <div>
    <a href="" class="button-outline-light mr-3" @click.prevent="onSsoSubmit()">Acceder</a>
  </div>
</template>

<script>
import { SecurityService } from "@/core/services";

export default {
    name: 'AccessButton',

    methods: {
      onSsoSubmit() {
        SecurityService.redirectToAuthorizeURL();
      }
    }
}
</script>

<style>
</style>