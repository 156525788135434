import {http} from '@/http'
import { Util } from "wize-admin";

export default {
  list(data, params) {
    return http.post('/payment-batches/list', data, {params})
  },
  downloadTemplate() {
    return http.get('/payment-batches/template/xlsx', {responseType: 'blob'})
  },
  uploadBatch(data){
    const formData = Util.getFormData(data);
    return http.post('/payment-batches', formData, {headers: { "Content-Type": "multipart/form-data" },})
  },
  listIdentifiers() {
    return http.get('/payment-batches/identifiers')
  },
  changeStatus(batchId, data) {
    return http.put(`/payment-batches/${batchId}/status`, data)
  },
}
