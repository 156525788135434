<template>
  <div class="page-container expense-line-editor">
    <div class="page-title-container">
      <h1 class="page-title">{{ editing ? "Editar línea de gasto" : "Nuevo línea de gasto" }}</h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12">
          <b-card
            tag="article"
            no-body
            style="max-width: 640px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header card-header d-flex align-items-center"
            >
              <h3>{{ editing ? "Datos de la línea de gasto" : "Datos de la nueva línea de gasto" }} </h3>
            </b-card-header>
            <b-card-body>
              <form
                class="col-12 custom-form custom-form--pusher"
                @submit.prevent="save"
                novalidate
              >
                <section class="form-content">
                  <div class="form-group">
                    <label for="description">Descripción *</label>
                    <input
                      id="description"
                      type="text"
                      v-model.trim="form.description"
                      autocomplete="off"
                      maxlength="100"
                      placeholder="Ingresar descripción"
                      class="custom-form-control"
                      :class="{
                        'custom-form-control-error':
                          $v.form.description.$error
                      }"
                    />
                    <FormError
                      v-if="
                        $v.form.description.$error &&
                        !$v.form.description.required
                      "
                      message="Descripción es requerida"
                    />
                  </div>
                  <div class="form-group-buttons text-right">
                    <router-link
                      :to="{ name: 'config-expense-line-list' }"
                      class="button button-light"
                    >
                      {{ editing ? 'Volver' : 'Cancelar' }}
                    </router-link>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </section>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Alert, FormError} from "wize-admin"

import {required} from "vuelidate/lib/validators";
import {ExpenseLineService} from "@/core/services";

export default {
  components: {
    FormError,
  },
  data() {
    return {
      form: {
        description: null
      },
      editing: false
    };
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;

      try {
        this.$store.dispatch("app/loading", true);
        const data = {...this.form};
        let response;
        if (!this.editing) response = await ExpenseLineService.save(data);
        else
          response = await ExpenseLineService.update(
            this.$route.params.id,
            data
          );
        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-expense-line-edit",
            params: {id: response.payload.id},
          });
          this.editing = true;
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await ExpenseLineService.get(id);
        const payload = response.payload;
        this.form = {...payload};
        this.editing = true;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
        this.$router.push({name: "config-expense-line-list"});
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  validations: {
    form: {
      description: {required}
    },
  },
  mounted() {
    if (this.$route.params.id) this.load(this.$route.params.id);
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/expense-line-editor.styl"
</style>
