import {LocalStorage} from 'wize-admin'
import {SecurityService} from '../core/services'

const state = {
  refreshToken: LocalStorage.getRefreshToken(),
  accessToken: LocalStorage.getAccessToken(),
  user: LocalStorage.getUser(),
  isTokenValid: false,
  ssoState: LocalStorage.getSsoState()
}

const getters = {
  isAuthenticated: state => state.user && state.user.email,
  getUser: state => state.user,
  getSsoState: state => state.ssoState,
  isTokenValid: state => state.isTokenValid,
};

const actions = {
  setTokens({commit}, payload) {
    const {accessToken, refreshToken} = payload;
    commit('isTokenValid', true)
    commit('accessToken', accessToken);
    commit('refreshToken', refreshToken);
  },
  setUser({commit}, payload) {
    commit('user', payload);
  },
  checkValidToken({dispatch, commit}){
    return new Promise((resolve, reject) => {
      SecurityService.isValidToken()
        .then(response => {
          if (response) {
            commit('isTokenValid', true)
            resolve()
          }
        }).catch(err => {
        reject(err);
        dispatch('logout')
      });
    });
  },
  logout({commit}) {
    return new Promise((resolve) => {
      commit('accessToken', null)
      commit('user', null)
      commit('isTokenValid', false)
      commit('refreshToken', null)
      resolve()
    })
  }
};

const mutations = {
  isTokenValid(state, value){
    state.isTokenValid = value;
  },
  accessToken(state, value) {
    value ? LocalStorage.setAccessToken(value) : LocalStorage.clearAccessToken();
    state.accessToken = value;
  },
  refreshToken(state, value) {
    value ? LocalStorage.setRefreshToken(value) : LocalStorage.clearRefreshToken();
    state.refreshToken = value;
  },
  user(state, value) {
    value ? LocalStorage.setUser(value) : LocalStorage.clearUser();
    state.user = value;
  },
  ssoState(state, value) {
    value ? LocalStorage.setSsoState(value) : LocalStorage.clearSsoState();
    state.ssoState = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
