import {http} from '@/http'

export default {
  list(data, params) {
    return http.post('/suppliers/list', data, {params})
  },
  changeStatus(supplierId, status) {
    return http.put(`/suppliers/${supplierId}/status`, null, {params: {status}})
  },
  recoverPassword(supplierId) {
    return http.put(`/suppliers/${supplierId}/recover-password`)
  },
  save(data) {
    return http.post('/suppliers', data)
  },
  update(supplierId, data) {
    return http.put(`/suppliers/${supplierId}`, data)
  },
  get(supplierId) {
    return http.get(`/suppliers/${supplierId}`)
  },
  getByCompanyId(companyId) {
    return http.get(`/suppliers/company-id/${companyId}`)
  },
  download(data) {
    return http.post('/suppliers/xlsx', data,  {responseType: 'blob'})
  },
}
