import {SSOUtils} from 'wize-admin'
import {http} from '@/http'
import store from '@/store'

export default {
  redirectToAuthorizeURL() {
    const ssoState = SSOUtils.getRandomState();
    store.commit('auth/ssoState', ssoState)
    const params = {
      client_id: process.env.VUE_APP_SSO_AUTH_CLIENT_ID,
      redirect_uri: process.env.VUE_APP_SSO_AUTH_REDIRECT_URI,
      response_type: 'code',
      scope: 'openid profile email',
      state: ssoState,
      nonce: '2',
    };
    const query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
    location.href = [process.env.VUE_APP_SSO_AUTH_ENDPOINT, query].join('?');

  },
  loginSSO(payload) {
    return http.post('/auth', payload)
  },
  refreshToken(payload) {
    return http.post('/auth/refresh-token', payload)
  },
  getUserInfo() {
    return http.get('/auth/me')
  },
  isValidToken() {
    return http.get('/auth/token')
  }
}
