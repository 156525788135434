<template>
  <div class="page-container pushers-list">
    <div class="page-title-container">
      <h1 class="page-title">Impulsadores</h1>
    </div>
    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <button
                type="button"
                class="filter d-flex align-items-center justify-content-between border-0"
                @click="showFilters = !showFilters"
              >
                <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                <div>Filtrar impulsadores</div>
              </button>

              <!-- <filter-menu-2
                :show="showFilters"
                @hide="showFilters = false"
                :options="filterOptions"
                :selectedOptionFilter="filters"
                @setFilters="setFilters"
              ></filter-menu-2> -->

              <div class="d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold m-0"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold mr-0"
                  v-b-tooltip.hover
                  title="Descargar"
                  @click="downloadReport()"
                >
                  <i class="ri-download-2-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold m-0"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>

              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Descargar"
                @click="downloadReport()"
              >
                <i class="ri-download-2-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por n° de documento, nombres y apellidos, celular, correo electrónico, serie de huellero"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />

              <div class="text-right mt-2 mt-md-0">
                <b-button
                  :to="{ name: 'config-pusher-create' }"
                  variant="none"
                  class="button button-primary ml-md-2"
                  >Agregar</b-button
                >
              </div>
            </div>
          </section>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filterList"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="text-white p-0 bg-transparent border-0 ml-2"
              @click="deleteFilter(name)"
            >
              <i class="ri-close-circle-line"></i>
            </button>
          </span>
        </div>
      </div>

      <div>
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Nombres y apellidos</span>
            </div>

            <div>
              <span>Número de documento</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span>{{ o.firstName }} {{ o.lastName }}</span>
                </div>

                <div>
                  <span>{{ o.identifier || "-" }}</span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="custom-table-mobile-options mt-3">
                <router-link
                  :to="{
                    name: 'config-pusher-details',
                    params: { pusherId: o.id },
                  }"
                  class="mr-2"
                >
                  Ver datos
                </router-link>

                <router-link
                  :to="{
                    name: 'config-pusher-edit',
                    params: { pusherId: o.id },
                  }"
                  class="mr-2"
                >
                  Editar
                </router-link>

                <a
                  href=""
                  @click.prevent="
                    changeStatus(o.id, PushersStatus.INACTIVE.key)
                  "
                  v-if="o.status === PushersStatus.ACTIVE.key"
                  class="mr-2"
                  >Suspender</a
                >

                <a
                  href=""
                  @click.prevent="changeStatus(o.id, PushersStatus.ACTIVE.key)"
                  v-if="o.status === PushersStatus.INACTIVE.key"
                  class="mr-2"
                  >Activar</a
                >

                <a
                  href=""
                  @click.prevent="download(o.contract.asset)"
                  v-if="o.contract && o.contract.asset"
                  class="mr-2"
                  >Descargar contrato</a
                >

                <a
                  href=""
                  @click.prevent="download(o.loan.asset)"
                  v-if="o.loan && o.loan.asset"
                  class="mr-2"
                  >Descargar comodato</a
                >
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Tipo de documento:</span
                    >
                    <span>{{ getTypeDocument(o.identifierType).label }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Número de documento:</span
                    >
                    <span>{{ o.identifier || "-" }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Celular:</span
                    >
                    <span>{{ o.mobile || "-" }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Proyecto:</span
                    >
                    <span>{{
                      o.projectType != null ? o.projectType.description : "-"
                    }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Zonal:</span
                    >
                    <span>{{
                      o.zonal != null ? o.zonal.description : "-"
                    }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Estado:</span
                    >
                    <span :class="`badge ${getStatus(o.status).color}`">{{
                      getStatus(o.status).label
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th class="pr-2">Nombres y apellidos</th>
              <th class="pr-2">
                Tipo <br />
                de documento
              </th>
              <th>
                Número <br />
                de documento
              </th>
              <th>Celular</th>
              <th>Proyecto</th>
              <th>Zonal</th>
              <th>Estado</th>
              <th class="pr-md-3">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="td-name pl-3">{{ o.firstName }} {{ o.lastName }}</td>
              <td class="cell-center">
                {{ getTypeDocument(o.identifierType).label }}
              </td>
              <td class="cell-center">{{ o.identifier || "-" }}</td>
              <td class="cell-center">{{ o.mobile || "-" }}</td>
              <td class="cell-center">
                {{ o.projectType != null ? o.projectType.description : "-" }}
              </td>
              <td class="cell-center">
                {{ o.zonal != null ? o.zonal.description : "-" }}
              </td>
              <td class="cell-center pl-md-2">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>
              <td class="cell-center d_option pr-md-2 position-relative">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Ver datos"
                      :to="{
                        name: 'config-pusher-details',
                        params: { pusherId: o.id },
                      }"
                    />
                    <DropdownItem
                      text="Editar"
                      :to="{
                        name: 'config-pusher-edit',
                        params: { pusherId: o.id },
                      }"
                    />
                    <DropdownItem
                      text="Suspender"
                      @click="changeStatus(o.id, PushersStatus.INACTIVE.key)"
                      v-if="o.status === PushersStatus.ACTIVE.key"
                    />
                    <DropdownItem
                      text="Activar"
                      @click="changeStatus(o.id, PushersStatus.ACTIVE.key)"
                      v-if="o.status === PushersStatus.INACTIVE.key"
                    />
                    <DropdownItem
                      text="Descargar contrato"
                      @click="download(o.contract.asset)"
                      v-if="o.contract && o.contract.asset"
                    />
                    <DropdownItem
                      text="Descargar comodato"
                      @click="download(o.loan.asset)"
                      v-if="o.loan && o.loan.asset"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
          v-if="list.length"
        />

        <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Constants,
  Util,
  Alert,
  PaginationMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
  FilterMenu,
  FilterMixin,
} from "wize-admin";
import { Constants as LocalConstants } from "@/core/utils";
import {
  ZonalService,
  ProjectTypeService,
  PusherService,
} from "@/core/services";

import fileDownload from "js-file-download";

export default {
  components: {
    SearchBar,
    DropdownTable,
    DropdownItem,
    FilterMenu
  },
  data() {
    return {
      list: [],
      PushersStatus: LocalConstants.PushersStatus,
      tooltipType: null,
      filterOptions: [
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.PushersStatus.ACTIVE,
            LocalConstants.PushersStatus.INACTIVE,
          ],
        },
        {
          label: "Tipo de documento",
          key: "identifierTypes",
          type: Constants.TypeFilters.MULTIPLE,
          options: [
            LocalConstants.IdentifierType.DNI,
            LocalConstants.IdentifierType.CE,
            LocalConstants.IdentifierType.PASAPORTE,
            LocalConstants.IdentifierType.CARNE_REFUGIO,
            LocalConstants.IdentifierType.CARNE_IDENTIDAD,
            LocalConstants.IdentifierType.CARNE_PERMANENCIA,
            LocalConstants.IdentifierType.DNI_EXTRANJERO,
          ],
        },
        {
          label: "Fecha de incorporación",
          key: "admission",
          type: Constants.TypeFilters.DATE,
          isRange: true
        },
        {
          label: "Fecha de finalización de contrato",
          key: "contractFinish",
          type: Constants.TypeFilters.DATE,
          isRange: true,
        },
        {
          label: "Huellero",
          key: "fingerSensor",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.BooleanTypes.YES,
            LocalConstants.BooleanTypes.NO,
          ],
        },
        {
          label: "Proyecto",
          key: "projectTypeIds",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
        {
          label: "Zonal",
          key: "zonalIds",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
      ],
    };
  },
  mixins: [PaginationMixin, FilterMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "firstName,lastName",
        };

        const data = { search: this.search, ...this.filters };
        const response = await PusherService.list(data, params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.PushersStatus);
    },
    getTypeDocument(key) {
      return Util.searchValue(key, LocalConstants.IdentifierType);
    },
    async download(asset) {
      try {
        if (asset) {
          this.$store.dispatch("app/loading", true);

          await Util.downloadFile(asset.url, asset.name);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    changeStatus(pusherId, status) {
      const self = this;
      Alert.dialog(
        "¿Desea cambiar el estado del pusher?",
        null,
        async function () {
          try {
            await self.$store.dispatch("app/loading", true);
            const resp = (await PusherService.changeStatus(pusherId, status))
              .payload;

            const obj = self.list.find((o) => {
              return o.id === resp.id;
            });
            if (obj) {
              const index = self.list.indexOf(obj);
              self.$set(self.list, index, resp);
            }
            Alert.success("Estado actualizado correctamente");
          } catch (e) {
            console.error(e);
            Alert.error(e.errors.message);
          } finally {
            await self.$store.dispatch("app/loading", false);
          }
        }
      );
    },
    async recoverPassword(pusherId) {
      try {
        await this.$store.dispatch("app/loading", true);
        await PusherService.recoverPassword(pusherId);
        Alert.success(
          "Se ha enviado un correo electrónico al pusher para que pueda restablecer su contraseña"
        );
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async downloadReport() {
      try {
        await this.$store.dispatch("app/loading", true);
        const data = { search: this.search, ...this.filters };
        const response = await PusherService.download(data);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    await this.loadData();

    const zonalList = (await ZonalService.listAll()).payload;
    const zonalOption = this.filterOptions.find((o) => o.key === "zonalIds");
    zonalList.forEach((b) =>
      zonalOption.options.push({ key: b.id, label: b.description })
    );

    const projectTypes = (await ProjectTypeService.listAll()).payload;
    const projectTypeOption = this.filterOptions.find(
      (o) => o.key === "projectTypeIds"
    );
    projectTypes.forEach((b) =>
      projectTypeOption.options.push({ key: b.id, label: b.description })
    );
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/pushers-list';
</style>
