<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    size="lg"
    id="reason-for-rejection-modal"
  >
    <div class="modal-header d-flex justify-content-between align-items-center">
      <span class="modal-title">Motivo de rechazo</span>
      <b-button class="modal-close-icon" @click="hideModal" aria-label="Close">
        <i class="ri-close-large-line"></i>
      </b-button>
    </div>

    <section class="modal-body-container">
      <simple-alert
        text="Por favor para rechazar un comprobante debe seleccionar el motivo de rechazo."
        type="light-gray"
        class="mt-2"
      >
        <template slot="icon">
          <span class="modal-icon mr-2">
            <i class="ri-error-warning-line"></i>
          </span>
        </template>
      </simple-alert>

      <form
        action=""
        @submit.prevent="confirm"
        class="custom-form mt-4"
        novalidate
      >
        <div class="form-group d-flex flex-column flex-md-row mb-0">
          <label for="rejectReason" class="label">Motivo de rechazo</label>

          <div class="flex-1">
            <FormSelect
              id="rejectReason"
              v-model="rejectReason"
              :items="rejectReasons"
              defaultOption="Elegir motivo de rechazo"
              :showError="$v.rejectReason.$error && !$v.rejectReason.required"
            />

            <FormError
              v-if="$v.rejectReason.$error && !$v.rejectReason.required"
              message="Motivo de rechazo es requerido"
            />
          </div>
        </div>

        <div class="form-group-buttons text-right">
          <button
            class="button button-light"
            type="button"
            @click.prevent="hideModal()"
          >
            Cancelar
          </button>
          <button class="button button-primary" type="submit">Confirmar</button>
        </div>
      </form>
    </section>
  </b-modal>
</template>
<script>
import { FormError, FormSelect, SimpleAlert, ModalMixin } from "wize-admin";
import { required } from "vuelidate/lib/validators";
import { Constants as LocalConstants } from "@/core/utils";

export default {
  name: "ReasonForRejectionModal",
  props: {
    rejectReasons: Array,
    paymentId: Number,
  },
  data() {
    return {
      rejectReason: null,
    };
  },
  mixins: [ModalMixin],
  validations: {
    rejectReason: { required },
  },
  components: {
    SimpleAlert,
    FormError,
    FormSelect,
  },
  methods: {
    confirm() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.$emit("confirm", this.paymentId, "¿Desea rechazar el documento?", {
        status: LocalConstants.PaymentStatus.REJECTED.key,
        rejectReasonId: this.rejectReason,
      });
      this.hideModal();
    },
    hideModal() {
      this.rejectReason = null;
      this.$emit("hide");
    },
  },
};
</script>

<style lang="stylus">
@import "~@/core/styles/components/reason-for-rejection-modal.styl"
</style>
