<template>
  <div>
    <section class="login">
      <LoginHeader />
      <main class="d-flex justify-content-center align-items-center">
        <div
          class="
            container
            d-flex
            justify-content-center
            align-items-center
            d-md-block
            justify-content-md-start
            align-items-md-start
          "
        >
          <div class="row justify-content-center">
            <section
              class="
                col-11 col-md-6 col-lg-7
                order-1 order-md-0
                text-center text-md-left
                pt-5
                animate__animated animate__fadeIn
              "
            >
              <h1 class="title font-weight-normal">
                Controla los pagos de tus proveedores fácilmente
              </h1>
              <p class="description text-secondary">
                Consolida todos los pagos y facturas de tus proveedores en un sólo portal. Tus proveedores se mantendrán informados cuando generes un pago a su nombre.
              </p>

              <section
                class="
                  d-flex
                  flex-column
                  align-items-center align-items-md-start
                  mt-4
                "
              >
                <AccessButton />

                <router-link to="/" class="button-blue mt-3"
                  >Ir a documentación</router-link
                >
              </section>
            </section>

            <section
              class="
                col-md-6 col-lg-5
                order-0 order-md-1
                d-flex
                justify-content-center
                align-items-center
                position-relative
              "
            >
              <img
                src="@/core/assets/images/login/factura.svg"
                alt="Imagen de bienvenida"
                class="invoice-image animate__animated animate__fadeIn"
              />

              <img
                src="@/core/assets/images/login/persona.svg"
                alt="Imagen de bienvenida"
                class="img-fluid admin-image animate__animated animate__fadeIn"
              />
            </section>
          </div>
        </div>
      </main>
    </section>
  </div>
</template>

<script>

import {SecurityService} from "@/core/services"
import {ErrorUtils, SSOUtils} from 'wize-admin'
import LoginHeader from "../Components/Header";
import AccessButton from "../Components/AccessButton";
import {defineAbilitiesFor} from '@/core/plugins/abilities'

export default {
  name: "LoginPage",
  components: {
    AccessButton,
    LoginHeader,
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      $ability: { from: "test" }
    }
  },
  data() {
    return {
      error: false,
      errorMsg: null,
    };
  },
  methods: {
    async loginSso() {
      this.errorMsg = null;
      this.loading = true;
      this.$store.commit('app/setLoading', true)
      const callbackParams = SSOUtils.getCallbackParameters(location.href);
      if (this.$store.getters["auth/getSsoState"] === callbackParams.state) {
        this.$store.commit("auth/ssoState", null);
        try {
          const response = await SecurityService.loginSSO(callbackParams);
          if (response.status !== "OK") {
            return (this.errorMsg = ErrorUtils.getErrorMessages(response));
          }
          await this.$store.dispatch("auth/setTokens", response.payload);
          const userResponse = await SecurityService.getUserInfo();
          await this.$store.dispatch("auth/setUser", userResponse.payload);
          defineAbilitiesFor(userResponse.payload.roles);
          await this.$router.push({name: "dashboard"});
        } catch (e) {
          console.error(e);
        } finally {
          this.$store.commit('app/setLoading', false)
        }
      } else {
        this.errorMsg =
            "El estado SSO no coincide, por favor intenta nuevamente";
      }
    },
  },
  mounted() {
    if (window.location.search) this.loginSso();
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/login-page';
</style>



